import imageLogo from "./images/logo.png";
import imagePhone from "./images/phone.png";
import imageWhatsapp from "./images/whatsapp.png";
import imageInsta from "./images/instagram.png";
import imageDiscord from "./images/discord.png";
import imageTwitch from "./images/twitch.png";

import "./App.css";
import "./styles/Responsive.css";

function App() {
  return (
    <>
      <img className="image_logo" src={imageLogo} alt="Logo da Pro Gamers" />
      <main>
        <section>
          <div className="div_title">
            <h1>Novidades</h1>
            <h3>em</h3>
            <h1>breve</h1>
          </div>

          <div className="div_phone_responsive">
            <img
              className="image_phone"
              src={imagePhone}
              alt="mockup do celular"
            />
          </div>

          <div className="div_text">
            <p>
              Enquanto isso, entre em contato conosco através das nossas{" "}
              <span>redes sociais</span>
            </p>
            <div className="div_icons">
              <a href="##" rel="noreferrer" target="_blank">
                <img src={imageWhatsapp} alt="Whatsapp da Pro Gamers" />
              </a>
              <a
                href="https://www.instagram.com/progamers.br/"
                rel="noreferrer"
                target="_blank"
              >
                <img src={imageInsta} alt="Instagram da Pro Gamers" />
              </a>
              <a
                href="https://discord.com/invite/XHFUSJ2NaK/"
                rel="noreferrer"
                target="_blank"
              >
                <img src={imageDiscord} alt=" Discord da Pro Gamers" />
              </a>
              <a
                href="https://www.twitch.tv/progamersbrasil/"
                rel="noreferrer"
                target="_blank"
              >
                <img src={imageTwitch} alt=" Twitch da Pro Gamers" />
              </a>
            </div>
          </div>
        </section>

        <div className="div_phone">
          <img
            className="image_phone"
            src={imagePhone}
            alt="mockup do celular"
          />
        </div>
      </main>
    </>
  );
}

export default App;
